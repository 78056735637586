<!-- 登录页面 -->
<template>
  <div class="elite_layout">
    <section class="el-container is-vertical">
      <header class="el-header" style="height: 60px">
        <div class="topNav">
          <ul
            role="menubar"
            class="el-menu-demo el-menu--horizontal el-menu"
            style="background-color: rgb(255, 255, 255)"
          >
            <div class="logo" tabindex="0">
              <img src="../../assets/images/images/logo.png" alt="" />
            </div>

            <li
              role="menuitem"
              tabindex="0"
              class="el-menu-item"
              style="
                color: rgb(0, 0, 0);
                border-bottom-color: transparent;
                background: none;
              "
              @click="$router.push('/')"
            >
              首页
            </li>
            <li
              role="menuitem"
              tabindex="0"
              class="el-menu-item is-active"
              style="
                color: rgb(0, 0, 0);
                border-bottom-color: transparent;
                background: none;
              "
              @click="$router.push('/elite_position')"
            >
              职位
            </li>
            <li
              role="menuitem"
              tabindex="0"
              class="el-menu-item"
              style="
                color: rgb(0, 0, 0);
                border-bottom-color: transparent;
                background: none;
              "
              @click="$router.push('/elite_company')"
            >
              企业
            </li>
            <li
              role="menuitem"
              tabindex="0"
              class="el-menu-item is-active"
              style="
                color: rgb(255, 153, 0);
                border-bottom-color: rgb(255, 153, 0);
                background: none rgb(255, 255, 255);
              "
              @click="$router.push('/elite_resume')"
            >
              简历
            </li>
            <li
              role="menuitem"
              tabindex="0"
              class="el-menu-item"
              style="
                color: rgb(0, 0, 0);
                border-bottom-color: transparent;
                background: none;
              "
              @click="$router.push('/elite_chat')"
            >
              聊天
            </li>
            <div v-if="weixin_nickname != null" class="menu_me" tabindex="0">
              <span class="el-popover__reference-wrapper"
                ><div
                  class="el-dropdown-link el-popover__reference"
                  aria-describedby="el-popover-5162"
                  tabindex="0"
                >
                  <span>{{ weixin_nickname }}</span
                  ><img :src="weixin_pic" />

                  <span> <a href="" @click="tuichu">退出</a></span>
                </div></span
              >
              <!----><!---->
            </div>

            <div class="menu_me" v-else>
              <div class="btn" @click="$router.push('/login')">企业登录</div>
              <div class="btnZP" @click="$router.push('/register')">
                我要招聘
              </div>
              <div class="btnZP" @click="$router.push('/elite_login')">
                我要找工作
              </div>
            </div>
          </ul>
        </div>
      </header>
      <main class="el-main">
        <div class="main">
          <!--显示简历信息 开始 -->
          <div class="elite_resume">
            <div class="resume">
              <div class="caozuo">
                <div class="resume_shelves">
                  <div role="switch" class="el-switch">
                    <el-switch
                      active-color="#58362A"
                      v-model="is_hide"
                      active-text="简历上架"
                      inactive-text="简历下架"
                      @change="chang_is_hide"
                    >
                      ></el-switch
                    >
                  </div>
                </div>
              </div>
              <div class="title_position">
                <p class="title">基本信息</p>
              </div>
              <div class="me">
                <div class="touxiang">
                  <img
                    src="./../../assets/images/test/boy.png"
                    v-if="list.gender === '男'"
                  />
                  <img src="./../../assets/images/test/girl.png" v-else />

                  <!-- 上传头像用的
                  <div class="shang">
                    <div class="avatar-uploader">
                      <div tabindex="0" class="el-upload el-upload--text">
                        <i class="el-icon-plus avatar-uploader-icon"></i
                        ><input
                          type="file"
                          name="file"
                          class="el-upload__input"
                        />
                      </div>
                    </div>
                  </div>
-->
                </div>
                <div class="xinxi">
                  <!-- 求职状态 先隐藏
                  <p>
                    ddd .
                    <span
                      class="career_progress"
                      style="color: rgb(154, 154, 154)"
                    >
                      求职状态： 暂不考虑
                      <i class="el-icon-edit"></i
                    ></span>
                  </p> -->

                  <p>
                    {{ list.name }}
                  </p>

                  <p>
                    <span v-if="list.resume_education_experience_list"
                      >{{
                        list.resume_education_experience_list[0]
                          .education_background
                      }}
                    </span>
                    &nbsp;&nbsp;<span>{{ list.age }}岁</span>&nbsp;&nbsp;<span
                      >{{ list.gender }}
                    </span>
                    &nbsp;&nbsp;<span>经验：{{ list.work_year }}年</span>
                    <!--<span class="span_border">已婚已育</span>-->
                  </p>
                  <p class="lianxi"><span>手机号：</span>{{ list.phone }}</p>
                  <p class="lianxi"><span>邮箱：</span>{{ list.email }}</p>
                </div>
                <div class="bianji">
                  <button
                    type="button"
                    class="el-button el-button--text"
                    style="color: rgb(255, 153, 0)"
                    @click="myopen"
                  >
                    <!----><!----><span>编辑</span>
                  </button>
                </div>
              </div>
              <div class="me_edit"></div>
              <div class="position">
                <p class="title">求职期望</p>
                <div class="conent">
                  <p>
                    <span
                      ><i class="icon"></i>
                      <span
                        v-for="(item, index) in list
                          .resume_expect_position_list[0].industry_data"
                        :key="index"
                        style="margin-right: 7px"
                        >{{ item.second_industry_name }}</span
                      >
                    </span>
                    <br />
                    <span
                      ><i class="icon"></i>

                      <span
                        v-for="(item, index) in list
                          .resume_expect_position_list[0].position_data"
                        :key="index"
                        style="margin-right: 7px"
                        >{{ item.third_position_name }}</span
                      >

                      <i class="icon"></i>
                    </span>
                    <br />
                    <span>
                      <i class="icon"></i>
                      <span style="margin-right: 7px"
                        >{{
                          list.resume_expect_position_list[0].place_data
                            .city_name
                        }}-{{
                          list.resume_expect_position_list[0].place_data
                            .district_name
                        }}</span
                      >
                    </span>

                    <span class="span_border"
                      ><i class="icon"></i>
                      {{ list.resume_expect_position_list[0].salary_min }}-{{
                        list.resume_expect_position_list[0].salary_max
                      }}元
                    </span>
                  </p>
                </div>
                <div class="bianji">
                  <button
                    type="button"
                    class="el-button el-button--text"
                    style="color: rgb(255, 153, 0)"
                    @click="myopen"
                  >
                    <!----><!----><span>编辑</span>
                  </button>
                </div>
              </div>
              <div class="work_experience">
                <p class="title">工作经历</p>
                <div
                  class="conent"
                  v-for="(item, index) in list.resume_work_experience_list"
                  :key="index"
                >
                  <p v-if="item.company_name != ''">
                    <span> {{ item.company_name }} </span
                    ><span> {{ item.position_name }} </span
                    ><span> {{ item.floor }}-{{ item.upper }} 元 </span
                    ><span class="span_border">
                      {{ item.start_year }}年{{ item.start_month }}月-{{
                        item.end_year
                      }}年{{ item.end_month }}月
                    </span>
                  </p>
                </div>
                <div class="bianji">
                  <button
                    type="button"
                    class="el-button el-button--text"
                    style="color: rgb(255, 153, 0)"
                    @click="myopen"
                  >
                    <!----><!----><span>编辑</span>
                  </button>
                </div>
              </div>
              <div class="educationalExperience">
                <p class="title">教育经历</p>
                <div class="conent">
                  <p
                    v-for="(
                      item, index
                    ) in list.resume_education_experience_list"
                    :key="index"
                  >
                    <span v-if="item.school != ''">
                      <span><i class="icon"></i> {{ item.school }} </span
                      ><span><i class="icon"></i> {{ item.major }} </span
                      ><span class="span_border"
                        ><i class="icon"></i> {{ item.start_year }}年{{
                          item.start_month
                        }}月-{{ item.end_year }}年{{ item.end_month }}月
                      </span></span
                    >
                  </p>
                </div>
                <div class="bianji">
                  <button
                    type="button"
                    class="el-button el-button--text"
                    style="color: rgb(255, 153, 0)"
                    @click="myopen"
                  >
                    <!----><!----><span>编辑</span>
                  </button>
                </div>
              </div>
              <div class="selfEvaluation">
                <p class="title">自我评价</p>
                <div class="pingjia">{{ list.self_evaluation }}</div>
                <div class="bianji">
                  <button
                    type="button"
                    class="el-button el-button--text"
                    style="color: rgb(255, 153, 0)"
                    @click="myopen"
                  >
                    <!----><!----><span>编辑</span>
                  </button>
                </div>
              </div>
            </div>
            <div
              class="el-dialog__wrapper"
              style="z-index: 2012; display: none"
            >
              <div
                role="dialog"
                aria-modal="true"
                aria-label="编辑"
                class="el-dialog"
                style="margin-top: 15vh; width: 40%"
              >
                <div class="el-dialog__header">
                  <span class="el-dialog__title">编辑</span
                  ><button
                    type="button"
                    aria-label="Close"
                    class="el-dialog__headerbtn"
                  >
                    <i class="el-dialog__close el-icon el-icon-close"></i>
                  </button>
                </div>
                <div class="el-dialog__body">
                  <div class="scroll">
                    <!----><!----><!----><!---->
                    <form class="el-form">
                      <div class="el-form-item">
                        <label class="el-form-item__label" style="width: 80px"
                          >自我评价</label
                        >
                        <div
                          class="el-form-item__content"
                          style="margin-left: 80px"
                        >
                          <div class="el-textarea">
                            <textarea
                              autocomplete="off"
                              maxlength="140"
                              placeholder="请输入自我评价"
                              class="el-textarea__inner"
                              style="min-height: 96px; height: 96px"
                            ></textarea
                            ><span class="el-input__count">0/140</span>
                          </div>
                          <!---->
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="checkbox" style="color: rgb(255, 0, 0)">
                    <!---->
                  </div>
                </div>
                <div class="el-dialog__footer">
                  <span class="dialog-footer"
                    ><button type="button" class="el-button el-button--default">
                      <!----><!----><span>取 消</span></button
                    ><button
                      type="button"
                      class="el-button btn el-button--primary"
                    >
                      <!----><!----><span>确 定</span>
                    </button></span
                  >
                </div>
              </div>
            </div>
          </div>

          <!--显示简历信息 结束-->
          <div class="elite_resume" id="bianji_content" style="display: none">
            <el-form ref="form" :model="form" label-width="100px">
              <div class="kong_resume"></div>
              <div class="el-dialog__wrapper" style="z-index: 5">
                <div
                  role="dialog"
                  aria-modal="true"
                  aria-label="完善简历信息，求职快人一步"
                  class="el-dialog"
                  style="margin-top: 15vh; width: 40%"
                >
                  <div class="el-dialog__header">
                    <span class="el-dialog__title"
                      >完善简历信息，求职快人一步</span
                    ><button
                      type="button"
                      aria-label="Close"
                      class="el-dialog__headerbtn"
                      @click="myclose"
                    >
                      <i class="el-dialog__close el-icon el-icon-close"></i>
                    </button>
                  </div>
                  <div class="el-dialog__body">
                    <div class="scroll">
                      <form class="el-form">
                        <div class="el-form-item is-required">
                          <label class="el-form-item__label" style="width: 80px"
                            >姓名</label
                          >
                          <div
                            class="el-form-item__content"
                            style="margin-left: 80px"
                          >
                            <div class="el-input">
                              <!----><input
                                v-model="inp_name"
                                type="text"
                                autocomplete="off"
                                placeholder="请输入姓名"
                                class="el-input__inner"
                              /><!----><!----><!----><!---->
                            </div>
                            <!---->
                          </div>
                        </div>
                        <div class="el-form-item is-required">
                          <label class="el-form-item__label" style="width: 80px"
                            >联系方式</label
                          >
                          <div
                            class="el-form-item__content"
                            style="margin-left: 80px"
                          >
                            <div class="el-input">
                              <!----><input
                                v-model="inp_phone"
                                type="text"
                                autocomplete="off"
                                placeholder="请输入联系方式"
                                class="el-input__inner"
                              /><!----><!----><!----><!---->
                            </div>
                            <!---->
                          </div>
                        </div>
                        <div class="el-form-item is-required">
                          <label class="el-form-item__label" style="width: 80px"
                            >年龄</label
                          >
                          <div
                            class="el-form-item__content"
                            style="margin-left: 80px"
                          >
                            <div class="el-input">
                              <!----><input
                                v-model="inp_age"
                                type="text"
                                autocomplete="off"
                                maxlength="2"
                                placeholder="请输入年龄"
                                class="el-input__inner"
                              /><!----><!----><!----><!---->
                            </div>
                            <!---->
                          </div>
                        </div>
                        <div
                          class="el-form-item is-required"
                          style="width: 100%"
                        >
                          <label class="el-form-item__label" style="width: 80px"
                            >性别</label
                          >

                          <el-select
                            v-model="inp_gender"
                            placeholder="请选择性别"
                          >
                            <el-option
                              v-for="item in optionsSex"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            ></el-option>
                          </el-select>
                        </div>
                        <div class="el-form-item is-required">
                          <label class="el-form-item__label" style="width: 80px"
                            >学历</label
                          >
                          <el-select
                            v-model="inp_education"
                            placeholder="请选择最高学历"
                          >
                            <el-option
                              v-for="item in options_edu"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            ></el-option>
                          </el-select>
                        </div>

                        <div class="el-form-item is-required">
                          <label class="el-form-item__label" style="width: 80px"
                            >经验</label
                          >
                          <div
                            class="el-form-item__content"
                            style="margin-left: 80px"
                          >
                            <div class="el-input">
                              <!----><input
                                v-model="inp_work_year"
                                type="text"
                                autocomplete="off"
                                maxlength="2"
                                placeholder="请输入几年经验"
                                class="el-input__inner"
                              /><!----><!----><!----><!---->
                            </div>
                            <!---->
                          </div>
                        </div>

                        <div class="el-form-item">
                          <label class="el-form-item__label" style="width: 80px"
                            >邮箱</label
                          >
                          <div
                            class="el-form-item__content"
                            style="margin-left: 80px"
                          >
                            <div class="el-input">
                              <!----><input
                                v-model="inp_email"
                                type="text"
                                autocomplete="off"
                                class="el-input__inner"
                              /><!----><!----><!----><!---->
                            </div>
                            <!---->
                          </div>
                        </div>
                      </form>
                      <form class="el-form">
                        <div class="el-form-item is-required">
                          <label class="el-form-item__label" style="width: 80px"
                            >期望行业</label
                          >

                          <el-cascader-multi
                            v-model="industry_id"
                            :data="indry"
                            separator=","
                            class="multi"
                            @change="change_indry"
                            ref="myindustry_lable"
                          >
                          </el-cascader-multi>
                        </div>
                        <div class="el-form-item is-required">
                          <label class="el-form-item__label" style="width: 80px"
                            >期望岗位</label
                          >
                          <el-cascader-multi
                            v-model="position_id"
                            :data="position"
                            separator=","
                            class="multi"
                            @change="change_position"
                            ref="myposition_lable"
                          >
                          </el-cascader-multi>
                        </div>
                        <div class="el-form-item is-required">
                          <label class="el-form-item__label" style="width: 80px"
                            >期望地区</label
                          >

                          <el-cascader
                            v-model="city_id"
                            :options="city"
                            @change="handlecity"
                            clearable
                          ></el-cascader>
                        </div>

                        <div class="el-form-item is-required">
                          <label class="el-form-item__label" style="width: 80px"
                            >期望薪资</label
                          >
                          <div
                            class="el-form-item__content"
                            style="margin-left: 80px"
                          >
                            <div class="salary">
                              <div class="el-input" style="width: 260px">
                                <!----><input
                                  type="text"
                                  v-model="inp_qw_number_min"
                                  autocomplete="off"
                                  placeholder="请输入最低薪资"
                                  class="el-input__inner"
                                /><!----><!----><!----><!---->
                              </div>
                              &nbsp; -&nbsp;
                              <div class="el-input" style="width: 260px">
                                <!----><input
                                  type="text"
                                  v-model="inp_qw_number_max"
                                  autocomplete="off"
                                  placeholder="请输入最高薪资"
                                  class="el-input__inner"
                                /><!----><!----><!----><!---->
                              </div>
                              &nbsp;&nbsp;&nbsp;元
                            </div>
                            <!---->
                          </div>
                        </div>
                      </form>
                      <form class="el-form">
                        <h3>工作经历</h3>
                        <div class="work_time">
                          <div class="el-form-item is-required">
                            <label
                              class="el-form-item__label"
                              style="width: 80px"
                              >公司名称</label
                            >
                            <div
                              class="el-form-item__content"
                              style="margin-left: 80px"
                            >
                              <div class="el-input">
                                <!----><input
                                  type="text"
                                  v-model="inp_company_name"
                                  autocomplete="off"
                                  placeholder="请输入公司名称"
                                  class="el-input__inner"
                                /><!----><!----><!----><!---->
                              </div>
                              <!---->
                            </div>
                          </div>
                          <div class="el-form-item is-required">
                            <label
                              class="el-form-item__label"
                              style="width: 80px"
                              >在职职位</label
                            >
                            <div
                              class="el-form-item__content"
                              style="margin-left: 80px"
                            >
                              <div class="el-input">
                                <!----><input
                                  v-model="inp_position_name"
                                  type="text"
                                  autocomplete="off"
                                  placeholder="请输入在职职位"
                                  class="el-input__inner"
                                /><!----><!----><!----><!---->
                              </div>
                              <!---->
                            </div>
                          </div>
                          <div class="el-form-item is-required">
                            <label
                              class="el-form-item__label"
                              style="width: 80px"
                              >在职时间</label
                            >

                            <el-date-picker
                              v-model="inp_company_date"
                              type="monthrange"
                              range-separator="至"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              align="right"
                              value-format="yyyy-MM"
                            ></el-date-picker>
                          </div>
                          <div class="el-form-item is-required">
                            <label
                              class="el-form-item__label"
                              style="width: 80px"
                              >在职薪资</label
                            >
                            <div
                              class="el-form-item__content"
                              style="margin-left: 80px"
                            >
                              <div class="salary">
                                <div class="el-input" style="width: 260px">
                                  <!----><input
                                    v-model="inp_number_min"
                                    type="text"
                                    autocomplete="off"
                                    placeholder="请输入最低薪资"
                                    class="el-input__inner"
                                  /><!----><!----><!----><!---->
                                </div>
                                &nbsp; -&nbsp;
                                <div class="el-input" style="width: 260px">
                                  <!----><input
                                    v-model="inp_number_max"
                                    type="text"
                                    autocomplete="off"
                                    placeholder="请输入最高薪资"
                                    class="el-input__inner"
                                  /><!----><!----><!----><!---->
                                </div>
                                &nbsp;&nbsp;&nbsp;元
                              </div>
                              <!---->
                            </div>
                          </div>
                        </div>
                      </form>
                      <form class="el-form">
                        <h3>教育经历</h3>
                        <div class="el-form-item is-required">
                          <label class="el-form-item__label" style="width: 80px"
                            >学校名称</label
                          >
                          <div
                            class="el-form-item__content"
                            style="margin-left: 80px"
                          >
                            <div class="el-input">
                              <!----><input
                                type="text"
                                v-model="inp_school"
                                autocomplete="off"
                                placeholder="请输入学校名称"
                                class="el-input__inner"
                              /><!----><!----><!----><!---->
                            </div>
                            <!---->
                          </div>
                        </div>
                        <div class="el-form-item is-required">
                          <label class="el-form-item__label" style="width: 80px"
                            >专业名称</label
                          >
                          <div
                            class="el-form-item__content"
                            style="margin-left: 80px"
                          >
                            <div class="el-input">
                              <!----><input
                                type="text"
                                v-model="inp_major"
                                autocomplete="off"
                                placeholder="请输入专业名称"
                                class="el-input__inner"
                              /><!----><!----><!----><!---->
                            </div>
                            <!---->
                          </div>
                        </div>
                        <div class="el-form-item is-required">
                          <label class="el-form-item__label" style="width: 80px"
                            >毕业时间</label
                          >

                          <el-date-picker
                            v-model="inp_school_date"
                            type="monthrange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            align="right"
                            value-format="yyyy-MM"
                          ></el-date-picker>
                        </div>
                      </form>
                      <!---->

                      <form class="el-form">
                        <h3>自我评价</h3>
                        <div class="el-form-item is-required">
                          <label class="el-form-item__label" style="width: 80px"
                            >自我评价</label
                          >
                          <div
                            class="el-form-item__content"
                            style="margin-left: 80px"
                          >
                            <div class="el-input">
                              <!----><input
                                v-model="inp_textarea"
                                type="text"
                                autocomplete="off"
                                placeholder="请输自我评价"
                                class="el-input__inner"
                              /><!----><!----><!----><!---->
                            </div>
                            <!---->
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="el-dialog__footer">
                    <span class="dialog-footer"
                      ><!----><button
                        type="button"
                        class="el-button btn el-button--primary"
                        @click="save"
                      >
                        <!----><!----><span>确 定</span>
                      </button></span
                    >
                  </div>
                </div>
              </div></el-form
            >
          </div>
        </div>
      </main>
    </section>
    <div
      id="bianji_div"
      class="v-modal"
      tabindex="0"
      style="z-index: 4; display: none"
    ></div>
  </div>
</template>    
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  create_resume,
  resume_info,
  work_experience_update,
  education_experience_update,
  basic_update,
  expect_position_update,
  resume_hide,
} from "@/api/login.js";
import axios from "axios";
import { create_user, show_agent_city } from "@/api/hr24_api/api_all.js";
import { showCity } from "@/api/me/index";
import $ from "jquery";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      weixin_nickname: sessionStorage.getItem("weixin_nickname"),
      weixin_pic: sessionStorage.getItem("weixin_pic"),
      optionsSex: [
        {
          label: "男",
          value: "男",
        },
        {
          label: "女",
          value: "女",
        },
      ],

      options_edu: [
        {
          label: "初中",
          value: "初中",
        },
        {
          label: "中专",
          value: "中专",
        },
        {
          label: "高中",
          value: "高中",
        },
        {
          label: "大专",
          value: "大专",
        },
        {
          label: "本科",
          value: "本科",
        },
        {
          label: "硕士",
          value: "硕士",
        },
        {
          label: "博士",
          value: "博士",
        },
        {
          label: "MBA",
          value: "MBA",
        },
      ],
      is_hide: false, //是否上架 false 是上架，true是下架
      inp_name: "", //姓名
      inp_phone: "", //手机号
      inp_gender: "", //性别
      inp_work_year: 0, //经验
      inp_age: "", //年龄
      inp_education: "", //学历
      inp_qw_number_min: "", //期望薪资最小值
      inp_qw_number_max: "", //期望薪资最大值

      inp_email: "", //邮箱

      inp_company_name: "", //工作经历 公司名称
      inp_position_name: "", //请输入在职职位
      inp_number_min: "", //在职薪资最大值
      inp_number_max: "", //在职薪资最小值

      inp_school: "", // 教育经历 学习名称
      inp_major: "", // 专业名称
      inp_textarea: "", //自我评价
      inp_company_date: [], //在职日期
      inp_school_date: [], //毕业时间

      city_id: [], //选中的 期望地区
      city: [], //期望地区

      //期望行业
      industry_id: [], //选中的行业
      indry: [], //行业data
      //期望岗位
      position_id: [], //选中的岗位
      position: [], //岗位data

      list: [], //简历详情
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    chang_is_hide: function (value) {
      // console.log("是否上架：", this.list.is_hide);
      console.log("是否上架：", value);

      let add = value == true ? 0 : 1; //是否上架

      console.log("是否上架 value：", add);
      resume_hide({ is_hide: add }).then((res) => {
        if (add == 1) {
          this.$message({
            message: "下架成功",
            type: "success",
          });
        } else {
          this.$message({
            message: "上架成功",
            type: "success",
          });
        }
      });
    },
    //退出登录
    tuichu: function () {
      sessionStorage.clear();
      this.$router.replace("/elite_login");
    },
    //关闭编辑窗口
    myclose: function () {
      $("#bianji_content").hide();
      $("#bianji_div").hide();
      this.$router.go(0);
    },
    //打开编辑窗口
    myopen: function () {
      $("#bianji_content").show();
      $("#bianji_div").show();
    },
    //选中的行业
    change_indry: function () {
      console.log("选中的行业");
      console.log(this.industry_id);
      if (this.industry_id.length > 5) {
        this.industry_id.pop();
        this.$message({
          message: "期望行业最多选5个",
          type: "warning",
        });
        return;
      }
    },
    //选中的岗位
    change_position: function () {
      console.log("选中的岗位");
      // console.log(this.$refs.myposition_lable.$children[0].$options.propsData);

      // console.log(
      //   this.$refs.myposition_lable.$children[0].$options.propsData.value
      // );
      // console.log(this.$refs.myposition_lable.$children["0"].value);

      // console.log(e);
      console.log(this.position_id);
      if (this.position_id.length > 5) {
        this.position_id.pop();
        this.$message({
          message: "期望岗位最多选5个",
          type: "warning",
        });
        return;
      }
    },

    //性别
    sexHandle(value) {
      console.log(value);
      this.gender = value;
      console.log(value);
      this.currentpage = 1;
    },

    update_key: function (obj) {
      let that = this;

      if (obj) {
        obj.forEach(function (row) {
          let children = [];
          if (row.children) {
            row.children.forEach(function (row1) {
              children.push({
                label: row1.name,
                value: row1.industry_id,
              });
            });
          }
          that.indry.push({
            label: row.name,
            value: row.industry_id,
            children: children,
          });
        });
      }
    },
    //保存简历信息
    save: function () {
      //验证信息
      if (this.inp_name === "") {
        this.$message({
          message: "请填写姓名",
          type: "warning",
        });
        return;
      }
      if (this.inp_gender === "") {
        this.$message({
          message: "请选择性别",
          type: "warning",
        });
        return;
      }

      if (this.inp_age === "") {
        this.$message({
          message: "请填写年龄",
          type: "warning",
        });
        return;
      }

      if (this.inp_email === "") {
        this.$message({
          message: "请填写邮箱",
          type: "warning",
        });
        return;
      }
      if (this.inp_qw_number_min === "") {
        this.$message({
          message: "请填写期望薪资最小值",
          type: "warning",
        });
        return;
      }
      if (this.inp_qw_number_max === "") {
        this.$message({
          message: "请填写期望薪资最大值",
          type: "warning",
        });
        return;
      }

      if (this.city_id.length == 0) {
        this.$message({
          message: "请选择期望地区",
          type: "warning",
        });
        return;
      }

      if (this.industry_id.length == 0) {
        this.$message({
          message: "请选择期望行业",
          type: "warning",
        });
        return;
      }
      if (this.position_id.length == 0) {
        this.$message({
          message: "请选择期望岗位",
          type: "warning",
        });
        return;
      }
      let ind_id = [],
        pos_id = [];
      console.log(" 请选择期望行业:", this.industry_id);
      this.industry_id.forEach((el) => {
        ind_id.push(el[1]);
      });

      this.position_id.forEach((el) => {
        pos_id.push(el[2]);
      });

      // console.log(" 请选择期望行业:", this.industry_id);
      // console.log(" 请选择期望行业:", ind_id.join(","));

      //       inp_age: "", //年龄  pos_id.join(",")
      //       inp_education: "", //学历
      //       inp_qw_number_min: "", //期望薪资最小值
      //       inp_qw_number_max: "", //期望薪资最大值

      //       inp_email: "", //邮箱

      //       inp_company_name: "", //工作经历 公司名称
      //       inp_position_name: "", //请输入在职职位
      //       inp_number_min: "", //在职薪资最大值
      //       inp_number_max: "", //在职薪资最小值

      //       inp_school: "", // 教育经历 学习名称
      //       inp_major: "", // 专业名称
      //       inp_textarea: "", //自我评价
      //       inp_company_date: [], //在职日期
      //       inp_school_date: [], //毕业时间

      //       city_id: [], //选中的 期望地区

      //       //期望行业
      //       industry_id: [], //选中的行业

      //       //期望岗位
      //       position_id: [], //选中的岗位

      let that = this;

      let position_name, industry_id_name;
      position_name =
        this.$refs.myposition_lable.$children[0].$options.propsData.value.join(
          ","
        );

      industry_id_name =
        this.$refs.myindustry_lable.$children[0].$options.propsData.value.join(
          ","
        );

      let json_education_background = JSON.stringify({
        education_background: this.inp_education,
      });

      let json_resume_expect_position_list = JSON.stringify({
        district_id: this.city_id[2],
        city_id: this.city_id[1],

        position_id: pos_id.join(","),
        position_id_name: position_name,
        industry_id: ind_id.join(","),
        industry_id_name: industry_id_name,

        salary_min: this.inp_qw_number_min,
        salary_max: this.inp_qw_number_max,
      });

      let DataObj = {
        province_id: this.city_id[0],
        city_id: this.city_id[1],
        district_id: this.city_id[2],

        name: this.inp_name,
        phone: this.inp_phone,
        gender: this.inp_gender,
        email: this.inp_email,
        age: this.inp_age,

        work_year: this.inp_work_year,
        position_id: this.position_id.join(","),
        position_name: position_name,
        education_background: this.inp_education,
        resume_education_experience_list: json_education_background,
        resume_expect_position_list: json_resume_expect_position_list,
        resume_work_experience_list: JSON.stringify({ company_name: "" }),
      };

      // // DataObj = {
      //         basic_id: that.data.list.id,
      //         update_data: JSON.stringify(update_data)
      //       }
      //       console.log(DataObj)
      //       call.requestd('/resume/v1/basic_update/', DataObj,

      let basic_id = this.list.id;
      if (basic_id) {
        // 有简历就更新简历       call.requestd('/resume/v1/basic_update/', DataObj,

        let update_data = {
            // 基本信息
            name: this.inp_name,
            gender: this.inp_gender,
            age: this.inp_age,
            phone: this.inp_phone,
            email: this.inp_email,
            work_year: this.inp_work_year,
          },
          DataObj = {
            basic_id: basic_id,
            update_data: JSON.stringify(update_data),
          };

        let education_background = {
          // 教育经历
          education_experience_id:
            that.list.resume_education_experience_list[0].id,
          update_data: JSON.stringify({
            education_background: this.inp_education,
          }),
        };

        basic_update(DataObj).then((res) => {
          console.log("基本信息保存成功");
          //简历保存完，在保存 教育经历，工作经历，自我介绍等信息

          education_experience_update(education_background).then((res) => {});

          //更新期望职位

          let DataObj_expect_position = {
            resume_expect_position_id:
              this.list.resume_expect_position_list[0].id,
            update_data: JSON.stringify({
              city_id: this.city_id[1],
              district_id: this.city_id[2],

              position_id: pos_id.join(","),
              position_id_name: position_name,
              industry_id: ind_id.join(","),
              industry_id_name: industry_id_name,
              salary_min: this.inp_qw_number_min,
              salary_max: this.inp_qw_number_max,
            }),
          };

          console.log("更新期望职位", DataObj_expect_position);

          expect_position_update(DataObj_expect_position).then((res) => {
            console.log("更新期望职位结果：", res);
          });

          this.save_works();

          this.$message({
            message: "保存成功！",
            type: "success",
          });
        });
      } else {
        //没有简历创建简历
        create_resume(DataObj).then((res) => {
          console.log("保存成功");
          //简历保存完，在保存 教育经历，工作经历，自我介绍等信息
          this.save_works();

          this.$message({
            message: "保存成功！",
            type: "success",
          });
        });
      }
    },

    save_works: function () {
      let list = this.list;

      // resume_info({}).then((res) => {
      //   console.log("获取简历详情");
      // });

      console.log("在职时间：", this.inp_company_date);
      let start_data_company = this.inp_company_date[0].split("-");
      let end_data_company = this.inp_company_date[1].split("-");

      let start_data_school = this.inp_school_date[0].split("-");
      let end_data_school = this.inp_school_date[1].split("-");

      //  Date.parse(this.inp_company_date[0]) / 1000,
      let that = this;

      console.log(
        "resume_work_experience_list=id ==",
        list.resume_work_experience_list[0].id
      );

      let DataObj = {
        work_experience_id: list.resume_work_experience_list[0].id,
        update_data: JSON.stringify({
          company_name: that.inp_company_name,
          position_name: that.inp_position_name,

          start_year: start_data_company[0], //在职日期
          start_month: start_data_company[1],
          end_year: end_data_company[0],
          end_month: end_data_company[1],
          floor: this.inp_number_min != "" ? this.inp_number_min : 0,
          upper: this.inp_number_max != "" ? this.inp_number_max : 0,
        }),
      };
      let DataObj_school = {
        education_experience_id: list.resume_education_experience_list[0].id,
        update_data: JSON.stringify({
          school: this.inp_school,
          major: this.inp_major,

          start_year: start_data_school[0], //在职日期
          start_month: start_data_school[1],
          end_year: end_data_school[0],
          end_month: end_data_school[1],
        }),
      };
      let DataObj_update = {
        basic_id: list.id,
        update_data: JSON.stringify({
          self_evaluation: this.inp_textarea,
        }),
      };

      work_experience_update(DataObj).then((res) => {
        console.log("ok1");
        education_experience_update(DataObj_school).then((res2) => {
          console.log("ok2");
          basic_update(DataObj_update).then((res3) => {
            console.log("ok3");
            console.log("简历创建成功");
            //进入简历显示页面
          });
        });
      });
    },

    //获取简历详情
    get_jianli: function () {
      resume_info({}).then((res) => {
        console.log("获取简历详情", res);

        this.list = res.data;

        // console.log("this.list.id = ", this.list.id);

        if (this.list.id != undefined && this.list.id > 0) {
          $("#bianji_content").hide();
          $("#bianji_div").hide();
        } else {
          this.myopen();
        }

        this.is_hide = this.list.is_hide == 0 ? true : false; //是否上架

        console.log("is_hide = ", this.is_hide);

        this.inp_name = this.list.name; //姓名
        this.inp_phone = this.list.phone; //手机号

        this.inp_gender = this.list.gender; //性别
        this.inp_age = this.list.age; //年龄
        this.inp_education =
          this.list.resume_education_experience_list[0].education_background; //学历

        this.inp_qw_number_min =
          this.list.resume_expect_position_list[0].salary_min; //期望薪资最小值
        this.inp_qw_number_max =
          this.list.resume_expect_position_list[0].salary_max; //期望薪资最大值

        this.inp_work_year = this.list.work_year; //经验

        this.inp_email = this.list.email; //邮箱

        this.inp_company_name =
          this.list.resume_work_experience_list[0].company_name; //工作经历 公司名称
        this.inp_position_name =
          this.list.resume_work_experience_list[0].position_name; //请输入在职职位
        this.inp_number_min = this.list.resume_work_experience_list[0].floor; //在职薪资最大值
        this.inp_number_max = this.list.resume_work_experience_list[0].upper; //在职薪资最小值

        this.inp_company_date = [
          this.list.resume_work_experience_list[0].start_year +
            "-" +
            this.list.resume_work_experience_list[0].start_month,
          this.list.resume_work_experience_list[0].end_year +
            "-" +
            this.list.resume_work_experience_list[0].end_month,
        ]; //在职日期

        this.inp_school = this.list.resume_education_experience_list[0].school; // 教育经历 学习名称
        this.inp_major = this.list.resume_education_experience_list[0].major; // 专业名称

        this.inp_school_date = [
          this.list.resume_education_experience_list[0].start_year +
            "-" +
            this.list.resume_education_experience_list[0].start_month,
          this.list.resume_education_experience_list[0].end_year +
            "-" +
            this.list.resume_education_experience_list[0].end_month,
        ]; //毕业时间

        this.inp_textarea = this.list.self_evaluation; //自我评价

        this.city_id = [
          this.list.resume_expect_position_list[0].place_data.province_code +
            "",
          this.list.resume_expect_position_list[0].place_data.city_code + "",
          this.list.resume_expect_position_list[0].place_data.district_code +
            "",
        ];
        console.log("默认选择地区：", this.city_id);

        //选中的 期望地区

        //   //期望行业
        this.list.resume_expect_position_list[0].industry_data.forEach(
          (item) => {
            this.industry_id.push([
              item.first_industry_id,
              item.second_industry_id,
            ]);
          }
        );
        console.log("默认 选中的行业：", this.industry_id);

        this.list.resume_expect_position_list[0].position_data.forEach(
          (item) => {
            this.position_id.push([
              item.first_position_id,
              item.second_position_id,
              item.third_position_id,
            ]);
          }
        );

        //   //期望岗位
        //  this.  position_id: [], //选中的岗位

        //赋值到变量中
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    //没有登录 请重新登录
    if (sessionStorage.getItem("token") == null) {
      this.$message({
        message: "请您先登录！",
        type: "warning",
      });

      this.$router.replace("/elite_login");
      return;
    }

    axios.get("/static/industry.json").then((res) => {
      // console.log("行业 列表2");
      this.update_key(res);
      // console.log(this.indry);
    });

    axios.get("/static/indry.json").then((res) => {
      // console.log("职位 列表");
      // console.log(res);
      this.position = res;

      // console.log("职位 列表2");

      // console.log(this.position);
    });

    showCity({}).then((res) => {
      // console.log(res);
      // this.city = [{ label: '唐山市', value: '130200', children: [] }]
      this.city = res.data;
    });
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.get_jianli();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
.topNav {
  width: 100%;
  height: 100%;
  background: #fff;
}
.topNav .el-menu-item:hover {
  outline: 0 !important;
  background: #fff !important;
}
.topNav .el-menu-item.is-active {
  background: #fff !important;
}
.el-menu-demo {
  width: 1200px;
  margin: auto;
}
.logo {
  float: left;
  height: 44px;
  padding-top: 16px;
  margin-right: 20px;
}
.logo > img {
  height: 26px;
}
.menu_me {
  float: right;
  width: 365px;
}
.el-dropdown-link {
  float: right;
  height: 60px;
  overflow: hidden;
}
.el-dropdown-link img {
  display: block;
  float: left;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 5px;
}
.el-dropdown-link span {
  display: block;
  float: left;
  height: 60px;
  line-height: 60px;
  padding: 0 12px;
  font-size: 16px;
  font-weight: 700;
}
.el-dropdown-link i {
  float: left;
  display: block;
  width: 20px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  margin-top: 5px;
}
.nav_con > p {
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}
.nav_con > p > a {
  color: #000;
}
.nav_con > p > a:hover,
.nav_con > p:hover {
  color: #f90;
}
.btn {
  background-color: rgba(253, 148, 15, 0.8) !important;
  border-radius: 6px;
}
.btn,
.btnZP {
  float: right;
  width: 100px;
  text-align: center;
  line-height: 38px;
  margin-top: 12px;
  margin-right: 20px;
  -webkit-box-shadow: hsla(0, 0%, 83.9%, 0.8) 2px 4px 1px 1px;
  box-shadow: 2px 4px 1px 1px hsla(0, 0%, 83.9%, 0.8);
  cursor: pointer;
}
.btnZP {
  border: 1px solid rgba(253, 148, 15, 0.8);
  border-radius: 6px;
}

.select_city {
  float: left;
  line-height: 61px;
  padding: 0 12px;
  font-size: 12px;
  color: #b47009;
  cursor: pointer;
}
.ziuzhiComponents {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}
.zhiqiu {
  width: 436px;
  height: 276px;
  position: absolute;
  top: 50%;
  margin-top: -200px;
  left: 50%;
  margin-left: -230px;
  background: #fff;
  padding: 12px;
  border-radius: 12px;
}
.zhiqiu > .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.zhiqiu > .item > p {
  float: left;
  width: 200px;
  line-height: 40px;
  border-radius: 6px;
  border: 1px solid #e6e4e4;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  margin: 14px auto;
  color: #202020;
}
.zhiqiu > .item > .index,
.zhiqiu > .item > p:hover {
  font-weight: 700;
  border-color: #f5ae55;
}
.header {
  padding: 0 12px;
  margin-bottom: 25px;
}
.header h4 {
  margin: 0 0;
}
.header p {
  margin: 6px 0;
  font-size: 14px;
  color: #8d92a1;
}
.footer {
  text-align: right;
  padding-right: 20px;
}
.elite_layout {
  min-width: 1250px;
  position: relative;
}
.main {
  margin: auto;
}
.el-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 0;
  z-index: 10;
}
.el-main {
  padding: 0;
}

.v-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
}

.btn {
  color: #fff !important;
  background-color: #ff8f00 !important;
  border: none !important;
  width: 100px;
}
.el-button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  font-weight: 500;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
  margin-top: -40px;
}

.scroll {
  max-height: 500px;
  border-bottom: 4px solid #858585;
  overflow-y: auto;
}

.el-select {
  width: 80% !important;
}
.multi {
  width: 80% !important;
}
.multi div {
  width: 10% !important;
}

//以下是显示简历的 样式
p {
  margin: 0;
}
.elite_resume {
  width: 800px;
  margin: auto;
  padding-top: 20px;
}
.card_margin {
  margin: 12px 0;
  position: relative;
}
.card_margin_a {
  margin: 12px 0;
  height: 150px;
}
.resume {
  width: 800px;
  margin: auto;
  margin-top: 56px;
  background: #fff;
  border-radius: 12px;
  padding: 20px 0;
}
.resume .el-card__body {
  padding: 12px 0;
}
.resume > div {
  position: relative;
}
.resume > div:nth-child(2) {
  margin-top: 12px;
}
.me {
  height: 226px;
}

.work_experience {
  height: 150px;
}
.educationalExperience {
  height: 80px;
}
.touxiang {
  position: absolute;
  width: 120px;
  height: 120px;
  top: 12px;
  left: 27px;
  cursor: pointer;
}
.touxiang img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.wu {
  background: #f8f8f8;
  color: #e9e9e9;
}
.shang,
.wu {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  line-height: 120px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}
.shang {
  display: none;
  background: rgba(51, 51, 51, 0.3803921568627451);
  color: #fff;
}
.shang .el-upload {
  width: 100%;
}
.touxiang > img {
  width: 120px;
  height: 120px;
}
.touxiang:hover .shang {
  display: block;
}
.xinxi {
  width: 610px;
  position: absolute;
  left: 180px;
  height: 200px;
}
.xinxi > p {
  margin: 0;
  line-height: 30px;
  font-size: 14px;
}
.xinxi > p:first-child {
  font-size: 20px;
  font-weight: 700;
  line-height: 40px;
}
.xinxi > p:nth-child(2),
.xinxi > p:nth-of-type() {
  font-size: 14px;
}
.xinxi > p:nth-child(2) > span {
  padding: 6px 6px;
}
.xinxi > p:nth-child(2) > span:first-child {
  padding-left: 0;
}
.xinxi > p:nth-child(2) > .span_border {
  padding: 0 12px;
  border-right: none;
}
.bianji {
  position: absolute;
  top: 0;
  right: 20px;
}
.title {
  width: 756px;
  font-size: 18px;
  margin: auto;
  margin-top: 12px;
  color: #000;
  font-weight: 700;
  padding: 6px;
  border-left: 4px solid #c54646;
}
.conent {
  padding-left: 20px;
  color: #ccc;
}
.conent > p {
  padding: 10px 0;
  color: #585858;
}
.conent > p > span {
  padding: 0 12px;
  border-right: 1px solid #ccc;
}

.conent > p > span:last-child {
  border-right: none;
}
.work_experience > div > p > span > span {
  padding: 0 12px !important;
  border-right: 1px solid #ccc;
}
.conent > p > .span_border {
  padding: 0 12px;
  border-right: none;
}
.pingjia {
  min-height: 80px;
  text-indent: 35px;
  padding: 12px;
  color: #484848;
  line-height: 24px;
}
.lianxi span {
  display: inline-block;
}
.lianxi:last-child span {
  display: inline-block;
  text-align: left;
}
.work_time {
  padding: 12px 0;
  border-bottom: 1px solid #ccc;
}
.work_time:last-child {
  padding: 12px 0;
  border-bottom: none;
}
.kong_resume {
  width: 1200px;
  margin: auto;
  height: 600px;
  background: #fff;
  line-height: 600px;
  margin-top: 56px;
  color: #111;
  font-size: 20px;
  text-align: center;
}
.kong_resume:hover {
  color: #f90;
  font-weight: 700;
  cursor: pointer;
}
.caozuo {
  padding: 0 12px;
  overflow: hidden;
}
.caozuo .el-switch__label.is-active {
  color: #2d7e08;
  font-size: 18px;
}
.resume_shelves {
  float: right;
}
.educationalExperience {
  min-height: 60px;
}
.checkbox {
  float: right;
  margin-top: 10px;
}
.checkbox .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #000;
}
.checkbox .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  color: #000;
  background-color: #ff8f00;
  border-color: #ff8f00;
}
.scroll {
  max-height: 500px;
  border-bottom: 4px solid #858585;
  overflow-y: auto;
}
.title_position {
  position: absolute;
  top: 0;
}
.career_progress {
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
}
</style>
